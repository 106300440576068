import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')

export const FormatNumber = (num)=>{
    
    let newNumber = Number(num)
    return  newNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


export const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
export const classID = Cookies.get('gaatclkysd')?CryptoJS.AES.decrypt(Cookies.get('gaatclkysd'), 'gaatschool').toString(CryptoJS.enc.Utf8):'null'
export const sectionID = Cookies.get('gaatsckayd')?CryptoJS.AES.decrypt(Cookies.get('gaatsckayd'), 'gaatschool').toString(CryptoJS.enc.Utf8):'null'
export const staffCode= Cookies.get('gaattkauscd')?CryptoJS.AES.decrypt(Cookies.get('gaattkauscd'), 'gaatschool').toString(CryptoJS.enc.Utf8):'null'
export const staffName = Cookies.get('gaattkstnm')?CryptoJS.AES.decrypt(Cookies.get('gaattkstnm'), 'gaatschool').toString(CryptoJS.enc.Utf8):'null'
export const Token = Cookies.get('gaattkamtk')?CryptoJS.AES.decrypt(Cookies.get('gaattkamtk'), 'gaatschool').toString(CryptoJS.enc.Utf8):undefined
export const config = {
    headers: { 'content-type': 'multipart/form-data'}
} 
export const Currency = '₦'




export  const API_URL= 'https://api.gaatschool.com/api';
export  const API_IMAGE = 'https://api.gaatschool.com/public/images';  

export  const ServerUrl = 'https://app.gaatschool.com/controller';
export  const ImagesUrl = 'https://app.gaatschool.com/images'; 
export  const StudentUrl = 'https://learners.gaatschool.com'; 
export  const LocalUrl = 'staff.gaatschool.com';
export const schoolName = 'GAAT INTERNATIONAL SCHOOL, ABUJA' 




//export  const LocalUrl = 'localhost';

 


